<template>
  <div :class="{'mt-8 w-full text-center': !this.$store.getters.getInPractice}">
    <p v-if="!this.$store.getters.getInPractice" class="mb-1 text-xl text-gray-900">
      Готови ли сте да затвърдите наученото?
    </p>
    <button @click="play()" v-if="!this.$store.getters.getInPractice" class="hover:grow fade bg-blue-500 rounded px-6 py-4 w-full lg:w-1/3 text-white font-semibold text-xl shadow hover:bg-blue-600">
      &#128170; Упражнявай се
    </button>
    <div v-if="this.$store.getters.getInPractice">

      <div :class="{'justify-center': inScore, 'justify-start': !inScore}" class="flex">
        <div v-if="!inScore" class="flex flex-col items-center">
          <svg @click="stop()" class="h-16 lg:h-32 cursor-pointer text-gray-800 hover:text-red-600" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          <SecondsLeft :threshold=10 ref="secondsLeft"/>
          <div class="mt-4 lg:mt-8 text-2xl text-gray-700 font-semibold">{{ questionNumber }} / {{ threshold }}</div>
        </div>

        <div class="w-4/5">
          <div v-if="!inScore" class="text-4xl lg:text-6xl text-blue-500 flex justify-center items-center">
            <div class="hidden lg:inline mr-2">КОЛКО Е</div>
            <div class="text-6xl lg:text-8xl">&#8730;</div>
            <div :class="{'radical-top': !mobile, 'radical-top-mobiel': mobile}">{{ question }}</div>
            <div class="hidden lg:inline ml-2">?</div>
          </div>

          <div class="text-center mt-4 flex justify-center">
            <svg v-if="inCorrectResponse" class="text-red-500 h-16 lg:h-32" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
            <span v-if="inCorrectResponse" class="mt-1 lg:mt-3 text-4xl lg:text-6xl text-green-500">{{ prevAnswer }}</span>

            <div v-if="inScore" class="w-full">
              <div class="mt-3 text-4xl lg:text-6xl text-blue-500">
                <span>{{ getScore }}</span>% успеваемост
              </div>
              <button @click="stop()" class="mt-8 fade bg-blue-500 rounded px-6 py-4 w-full lg:w-1/3 text-white font-semibold text-xl shadow hover:bg-blue-600">
                &#127968; Към началото
              </button>
            </div>

            <input v-show="showResponseInput && !inScore" type="text" v-model="response" @keydown="onlyNumber" class="pointer-events-none lg:pointer-events-auto shadow-sm p-4 outline-none bg-white rounded border border-gray-500 text-4xl lg:text-6xl text-right text-gray-700 h-16 lg:h-32" placeholder="?" spellcheck="false" autocomplete="off" id="answer" style="width: 170px;" />
          </div>

        </div>

      </div>

      <Keyboard v-if="!inScore" />

    </div>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import SecondsLeft from "./SecondsLeft";
import Keyboard from "./Keyboard";
export default {
  name: 'Practice',
  components: {
    SecondsLeft,
    Keyboard,
  },
  data() {
    return this.getInitialData()
  },
  mounted() {
    this.$bus.$on('secondsLeftThresholdReached', () => {
      this.clear()
      this.generateQuestion()
    })
  },
  methods: {
    getInitialData() {
      return {
        focusInterval: '',
        question: '',
        answer: '',
        prevAnswer: '',
        response: '',
        showResponseInput: true,
        correctResponse: false,
        inCorrectResponse: false,
        threshold: 20,
        questionNumber: 0,
        isAnswerSingleDigit: false,
        inScore: false,
        correctResponsesTotal: 0,
        mobile: false,
      }
    },
    setFocusInterval() {
      this.focusInterval = setInterval(() =>
        document.getElementById('answer').focus()

      , 500);
    },
    clearFocusInterval() {
      clearInterval(this.focusInterval);
    },
    play() {
      if (isMobile) {
        this.mobile = true
      }
      this.setFocusInterval()
      this.$store.dispatch('setInPractice', true)
      this.generateQuestion()
    },
    stop() {
      if (this.inScore) {
        this.clearAll()
        this.$store.dispatch('setInPractice', false)
        return
      }
      this.clearFocusInterval()
      if (this.isThresholdReached()) {
        this.$bus.$emit('inScore')
        this.inScore = true
        return
      }
      this.clearAll()
      this.$store.dispatch('setInPractice', false)
    },
    generateQuestion() {
      if (this.isThresholdReached()) {
        this.stop()
        return
      }
      this.answer = Math.floor(Math.random() * 20 + 1)
      if (this.answer === this.prevAnswer) {
        this.generateQuestion()
        return
      }
      this.prevAnswer = this.answer
      this.question = Math.pow(this.answer, 2)
      this.questionNumber++

      this.isAnswerSingleDigit = this.answer < 10 ? true : false

      this.$bus.$emit('questionGenerated')
    },
    isResponseCorrect() {
      return this.response == this.answer ? true : false;
    },
    isThresholdReached() {
      return this.questionNumber >= this.threshold;
    },
    clear() {
      this.answer = '';
      this.question = '';
      this.response = '';
    },
    clearAll() {
      Object.assign(this.$data, this.getInitialData())
    },
    showError() {
      this.inCorrectResponse = true
      this.showResponseInput = false
    },
    clearError() {
      this.inCorrectResponse = false
      this.showResponseInput = true
    },
    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57 && keyCode < 96 || keyCode > 105) && keyCode != 8) {
        $event.preventDefault()
        return
      }
      if ([48, 96].includes(keyCode) && !this.response.length) {
        $event.preventDefault()
        return
      }
    },
  },
  computed: {
    getScore() {
      if (this.correctResponsesTotal) {
        return (this.correctResponsesTotal / this.threshold * 100).toFixed(0)
      }
      return 0
    },
  },
  watch: {
    response() {
      if (this.answer && this.response.length == this.answer.toString().length) {

        this.$bus.$emit('freezeTimer')

        if (!this.isResponseCorrect()) {
          this.showError()
        } else {
          this.correctResponsesTotal++
        }

        let timeout = this.isResponseCorrect() ? 0 : 1100;

        setTimeout(() => {
          this.clearError()
          this.clear()

          if (this.isThresholdReached()) {
            this.stop()
            return
          }

            this.generateQuestion()
        }, timeout);

      }
    }
  },
}
</script>
<style scoped>
.radical-top {
  text-decoration: overline;
  margin-top: 32px;
  margin-left: -16px;
}
.radical-top-mobile {
  text-decoration: overline;
  margin-top: 10px;
  margin-left: -10px;
}
</style>