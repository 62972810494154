<template>
  <div class="mt-8 w-full text-center">
    <h2 class="text-xl text-gray-900">Намери корен квадратен</h2>
    <div class="mt-1 text-4xl">
      <input type="text" v-model="sqrt" :class="{'w-2/4': typing, 'w-full': !typing}" class="text-2xl border p-3 rounded focus:outline-none focus:border-blue-300 shadow-sm lg:w-1/3" placeholder="Корен квадратен от..." />
      <span v-if="result" class="ml-5 text-2xl">&#61;</span>
      <span v-if="result" class="ml-5 text-4xl">{{ result }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Find',
  data() {
    return {
      sqrt: '',
      result: '',
      typing: false,
    }
  },
  methods: {
    clear() {
      this.result = '';
    }
  },
  watch: {
    sqrt() {
      this.clear();
      if (!isNaN(this.sqrt) && isFinite(this.sqrt)) {
        this.result = Math.sqrt(this.sqrt)
      }
      this.$store.dispatch('setFindSquare', this.sqrt)
    },
    result() {
      this.typing = this.result ? true : false
    }
  },
}
</script>