import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueCookies from 'vue-cookies'
// @ points in src
import '@/assets/css/app.css'

Vue.use(VueCookies)

Vue.config.productionTip = false

Vue.prototype.$bus = new Vue()

new Vue({
  store,
  render: function (h) { return h(App) },
}).$mount('#app')
