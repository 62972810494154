<template>
  <div class="lg:hidden flex flex-wrap w-full bg-gray-200 rounded select-none mt-4 md:mt-0" style="touch-action: manipulation;">
    <div v-for="(n, index) in 9" @click="[type(n), clicked($event)]" class="border-b border-r border-gray-300 w-1/3 text-center px-6 py-4 text-2xl font-bold cursor-pointer">{{ n }}</div>
    <div class="w-1/3 px-6 py-4 text-xl font-bold border-r border-gray-300 cursor-pointer">&nbsp;</div>
    <div @click="[type(0), clicked($event)]" class="w-1/3 px-6 py-4 text-2xl font-bold text-center border-r border-gray-300 cursor-pointer">0</div>
    <div @click="[erase(), clicked($event)]" class="w-1/3 px-6 py-4 flex justify-center cursor-pointer">
      <svg class="w-8 h-8" viewBox="0 0 20 20">
        <path fill="currentColor" d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    type(digit) {
      if (digit === 0 && !this.$parent.response.length) {
        return
      }
      this.$parent.response += digit;
    },
    erase() {
      this.$parent.response = '';
    },
    clicked(e) {
      e.target.classList.add('bg-gray-300')
      setTimeout(() => e.target.classList.remove('bg-gray-300'), 200)
    }
  }
}
</script>