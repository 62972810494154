import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        inPractice: false,
        findSquare: '',
    },
    mutations: {
        mutateInPractice(state, status) {
            state.inPractice = status
        },
        mutateFindSquare(state, status) {
            state.findSquare = status
        },
    },
    actions: {
        setInPractice(state, payload) {
            this.commit('mutateInPractice', payload)
        },
        setFindSquare(state, payload) {
            this.commit('mutateFindSquare', payload)
        }
    },
    modules: {},
    getters: {
        getInPractice: state => state.inPractice,
        getFindSquare: state => state.findSquare
    },
})