<template>
  <div class="mt-8">
    <h2 class="text-xl text-gray-900">Първите 20</h2>
    <div class="mt-1 bg-white shadow-sm p-4 rounded">
      <ul class="text-sm lg:text-2xl" style="column-count: 3">
        <li v-for="idx in 20" :key="idx" :class="{'mb-1': true, 'font-bold': looking == idx*idx}"><span class="font-bold">&#8730;</span>{{ idx*idx }} &#61; {{ idx }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SqrtList',
  props: ['looking'],
  data() {
    return {

    }
  }
}
</script>