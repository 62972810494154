<template>
  <div class="min-h-screen flex flex-col bg-gray-200">
    <div :class="{'p-4': !this.$store.getters.getInPractice, 'justify-center': this.$store.getters.getInPractice}" class="container mx-auto flex flex-col flex-grow lg:justify-center lg:p-0">
      <div v-if="!this.$store.getters.getInPractice">
        <h1 class="font-semibold text-4xl text-gray-900">a&sup2; &#61; x</h1>
        <div class="mt-2 rounded bg-white shadow-sm p-4 text-lg">
          <div class="mb-1">Корен квадратен (<span class="text-xl">&#8730;</span>) е произведението на число умножено по себе си.</div>
          <div>Пример: <span class="text-2xl">&#8730;</span><span class="border-t border-gray-900" style="margin-left: -2px;">49</span> (чете се: корен квадратен от 49) е 7, защото 7&times;7 е 49.</div>
        </div>
      </div>

      <SqrtList :looking="this.$store.getters.getFindSquare" v-if="!this.$store.getters.getInPractice" />

      <div :class="{'flex flex-col items-center': !this.$store.getters.getInPractice}">
        <Find v-if="!this.$store.getters.getInPractice" />

        <Practice />
      </div>
    </div>
    <footer class="flex justify-center w-full text-sm mb-1">
      with <span class=" ml-1 mr-1">&#10084;</span> by imakedonski
    </footer>

  </div>
</template>

<script>
import SqrtList from './SqrtList.vue'
import Find from './Find.vue'
import Practice from './Practice.vue'

export default {
  name: 'Landing',
  components: {
    SqrtList,
    Find,
    Practice,
  },
  data() {
    return {
      running: false,
    }
  },
  mounted() {

  },
  computed: {

  },
  methods: {

  }
}
</script>